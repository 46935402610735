.stream {
  :global {
    .video-js {
      position: absolute;
      top: 0;
      left: 0;
      background: black;
    }
    .vjs-tech {
      pointer-events: none;
    }
    width: 100%;
    // height: 100vh;
    align-items: center;
    justify-content: center;
    // flex-grow:1;
    height:100%;
    .col {
      padding:10px !important;
      >.plyr {
        max-height: calc(100vh - 20px);
      }
    }
  }
}