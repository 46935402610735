// $title-font-size: 1.5em;
$green1: #8bc057;
$green2: #89ce54;
$green3: #a8da76;
$green4: #82C365;
$green5: #85B75F;
$green6: #85db43d4;
$green7: #86B442;

$blue1: #00538c;
$blue2: #4b88b6;
$blue3: #65a3ff;
$blue4: #2A5B8C;
$blue5: #4B80AC;

$textShadow: 1px 1px 2px rgba(0, 0, 0, 0.6);

$backgroundRadius: 30px;
$backgroundInnerRadius: 28px;
$buttonHeight: 60px;
$topHeight: 60px;
$buttonRadius: 8px;
$backgroundBorderSize: 6px;
$footerSize: 100px;
$innerTextContainerSize: 80px;

@mixin colored-button($color) {
  width: calc(70%);
  height: $buttonHeight;
  border-radius: $buttonRadius;
  background-color: $color;
  border:none;
  font-family: 'Roboto-Bold';
  font-size: 20px !important;
  line-height: 20px;
  text-shadow: $textShadow;
  &.disabled {
    font-size: 20px !important;
  }
}

@mixin janssen-card {
  height: calc(100vh - $backgroundBorderSize*2 - 10px);
  background-color:white;
  position: relative;
  display: flex;
  border-radius: $backgroundRadius;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

@mixin container {
  overflow: hidden;
  border-radius: $backgroundInnerRadius;
  position: relative;
  max-height: calc(100% - $backgroundBorderSize*2);
  max-width: calc(100% - $backgroundBorderSize*2);
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: stretch;
}

@mixin top-title {
  max-height: $topHeight;
  background-color:$green3;
  color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex:1;
  position: relative;
  h1 {
    // font-size: $title-font-size;
    font-family: 'Roboto-Bold';
    text-shadow: $textShadow;
    font-size: 18px;
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

@mixin background-logo {
  // position:absolute;
  height: 40%;
  max-height: 250px;
  >div {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
  }
  // padding-bottom: 75%;
  // top: 50%;
  // transform: translateY(-50%);
  // image-rendering: crisp-edges;
}

@mixin scrollable {
  &::-webkit-scrollbar {
    width: 10px;
    position: absolute;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5); 
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 10px $green6/*rgba(97, 77, 211, 0.726)*/;
  }
  overflow: auto;
  overflow-y: overlay;
}

@mixin inner-text-bubble {
  border: none;
  color: white;
  height: calc(100% - 10px);
  margin-top:5px;
  // margin-left: 15px;
  width: calc(100% - 20px);
  padding: 0;
  background-color: $blue5;
  padding:20px;
  &.selected {
    background-color: $blue3;
  }
}

@mixin header {
  flex: 1;
  // max-height: 80px;
  max-height: 70px;
  padding-top:10px;
  padding-left: 5px;
  padding-right: 5px;
  .col {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    padding-left: 3px;
    padding-right: 3px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      @include colored-button($green3);
      width: 100% !important;
      height: 80% !important;
      padding: 2px !important;
      margin: 0 !important;
      text-shadow: $textShadow;
      font-family: 'Roboto-Regular' !important;
      font-size: 16px !important;
    }
  }
}
