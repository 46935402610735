@import 'global.scss';

.cities {
  >div {
    &:first-child {
      @include container;
    }
  }
  :global {
    @include janssen-card;
    .row {
      //
      &.header {
        @include top-title;
      }
      &.body {
        @include scrollable;
        overflow: auto;
        flex:1;
        >.col {
          .row {
            .col {
              position: relative;
              .badge {
                display: block;
                position: absolute;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
              }
            }
          }
        }
      }
      &.footer {
        height:5%;
        display: none;
      }
      //
      align-items: center;
      // background-color:red;
      align-content: space-between;
      .col {
        text-align: center;
        position: relative;
        &.city {
          height: 23vh;
          width: calc(100% - 20px);
          // margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor:pointer;
          &.disabled {
            cursor:default !important;  
          }
          span {
            // color: white !important;
          }
        }
        .buttons {
          position: relative;
          button {
            position: absolute;
            &.left {
              left:0;
            }
            &.right {
              right: 0;
            }
          }
        }
      }
    }
  }
}

.city {
  >div {
    &:first-child {
      @include container;
    }
  }
  :global {
    @include janssen-card;
    .row {
      position: relative;
      &.header {
        cursor: pointer;
        @include top-title;
        position: relative;
        .back {
          position: absolute;
          left: 0;
          height: 100%;
          display:flex;
          justify-content: center;
          align-items: center;
          min-width: 25px;
          font-size: 20px;
          z-index: 99;
        }
        /*
        &::before {
          z-index: 2;
          width: 20px;
          content: '<';
          height: auto;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          font-size: 2em;
        }
        */
        &::after {
          content:"";
          position: absolute;
          z-index: 1;
          height: 100%;
          width: 20px;
          left:0;
          top: 0;
        }
        .col {
          // height: 100%;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
      &.body {
        flex:1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        .badge {
          width: 100%;
          height: 80%;
          max-height: 250px;
          display:block;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      &.footer {
        flex:1;
        justify-content: center;
        align-items: center;
        max-height: $footerSize;
        button {
          @include colored-button($blue3);
        }
      }
    }
  }
}

.ending {
  :global {
    
  }
}

.patientCase {
  :global {
    
  }
}

.quizz {
  >div {
    &:first-child {
      @include container;
    }
  }
  :global {
    @include janssen-card;
    text-align: center;
    color: white;
    .header {
      max-height: 100px;
      flex:1;
      .pistolero {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
      }
      .col {
        min-width: calc(100% / 3);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        .row {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          flex: 1;
          width: 100%;
          &:first-child {
            max-height: 50%;
          }
          img {
            width: auto;
            max-height: calc(100% - 20px);
          }
        }
        span {
          min-width: 100px;
          max-height: calc(100% - 20px);
          justify-content: center;
          align-items: center;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          display: flex;
          flex: 1;
        }
        &.score {
          .row {
            &:nth-child(2) {
              max-height: 30%; 
            }  
            span {
              display: block;
              width: 100%;
              height: 100%;
              max-height: 100%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
          .score-info {
            border-radius: 10px;
            display: inline-block;
            text-align: center;
            color: white;
            max-width: 60% !important;
            width: 60% !important;
            background: $green7;
            height: 85%;
            font-style: normal;
          }
        }
      }
    }
    .body {
      //height: 70%;
      flex: 1;
      overflow: auto;
      @include scrollable;
      .question {
        border-radius: 8px;
        min-height: 80px;
        background: $green3;
        margin: 10px !important;
        width: calc(100% - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        font-family: 'Roboto-Medium';
        font-size: 16px;
        line-height: 16px;
      }
      .answer {
        min-height: $innerTextContainerSize;
        .col {
          button {
            @include inner-text-bubble;
            font-family: 'Roboto-Medium' !important;
            font-size: 15px !important;
            line-height: 15px !important;
          }
        }
        // line-height: 2.0em;
        // height: 20px;
        // border-radius: 8px;
        // border: 1px solid grey;
        // background-color: white;
        // margin: 10px !important;
        // height: 40px;
        // height: 120px;
        // width: calc(100% - 20px);
        // button {
        //   height: 100%;
        //   width: 100%;
        //   &.selected {
        //     background-color: rgb(88, 156, 196);
        //   }
        // }
      }
    }
    .footer {
      max-height: $footerSize;
      flex:1;
      .col {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          @include colored-button($blue3);
        }
      }
      /*height: 15%;
      .col {
        width: 100%;
        height: 100%;
        button {
          width: 80%;
          height: 80%;
        }
      }*/
    }
  }
}

.rules {
  :global {
    .back {
      button {
        border: none;
        background: none;
        outline: 0;
        &:focus {
          outline:0;
        }
      }
    }
    .rule-image {
      padding: 8px!important;
      @include scrollable;
      display: flex;
      justify-content:center;
      align-items:center;
      >div {
        max-height: 390px;
        min-height: 250px;
        flex: 1;
        display: flex;
        justify-content:center;
        align-items:center;
        height:50%;
        text-align:center;
        flex-direction: column;
        color: white;
        text-shadow: $textShadow;
        font-size: 1.0em;
        line-height: 1.2em;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 100%;
        span {
          font-family: 'Roboto-Regular';
          max-width: 75%;
        }
      }
    }
  }
}

.view {
  width: 100%;
  margin: 0;
  padding: 0;
  :global {
    .col, .row {
      padding: 0;
      margin: 0;
    }
  }
}

.waiting, /*.welcome,*/ .ending {
  >div {
    &:first-child {
      @include container;
    }
  }
  :global {
    @include janssen-card;
    .row {
      &.header {
        height: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        //
        font-size: 2em;
        font-weight: bold;
      }
      &.body {
        height: 70%;
      }
      &.footer {
        height:25%;
        button {
          width: calc(60%);
          margin-left: 20%;
          height: 80%;
        }
      }
    }
    position: relative;
    .score {
      // opacity:1.0;
      // filter: contrast(400%) brightness(5.0) grayscale(100%);
      // mix-blend-mode: hard-light;
      max-width: 60px;
      display:flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      position: absolute;
      right: 0;
      top: 10px;
      .row {
        &:nth-child(1) {
          height: 100%;
        }
        justify-content: center;
        span {
          display: block;
          width: 40px;
          height: 40px;
          max-height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .score-info {
        border-radius: 8px;
        display: inline-block;
        text-align: center;
        color: white;
        max-width: 40px !important;
        width: 40px !important;
        background: $green7;// #4d6c20;
        font-style: normal;
        font-size: 12px;
      }
    }
    
    .col {
      box-sizing: border-box;
      // overflow: auto;
      max-width: 100%;
      position: relative;
      height: 100%;
      .title {
        display:flex;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        >.row:first-child {
          .col {
            min-height:20px;
            flex:1;
            &:not(:nth-child(2)) {
              max-width: 40px;
            }
            &:nth-child(2) {
              border-left:solid 3px #2A5B8C;
              border-right:solid 3px #2A5B8C;
            }
          }
        }
        .panel {
          border: 3px solid $green5;
          margin-left:20px;
          max-width: calc(100% - 40px);
          min-height:60px;
          background-color: $blue4;
          display: flex;
          align-items: center;
          justify-content: center;
          color:white;
          font-family: 'Roboto-Bold';
          font-size: 24px;
          line-height: 28px;

          // font-weight: bold;
          // font-size: 1.5em;
        }
      }
      .image {
        @include background-logo;
      }
    }
    .message {
      color: white;
      position: absolute;
      bottom:0;
      height: 120px;
      width: 100%;
      padding:5px;
      padding-left:15px;
      padding-right: 15px;
      // margin-left:50px;
      // width:calc(100% - 100px) !important;
      display: flex;
      flex-direction: column;
      .col {
        font-family: 'Roboto-Bold';
        font-size: 24px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        &:nth-child(2) {
          font-size: 16px;
          line-height: 18px;
        }
        .row {
          &:nth-child(2) {
            font-size: 16px;
            line-height: 18px;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex:1;
          }
        }
      }
    }
  }
}

.ending {
  :global {
    .row {
      &.header {
        height: 30%;
        text-align: center;
      }
      &.body {
        height: 60%;
        .image {
          top: 40% !important;
        }
      }
      &.footer {
        height:10%;
      }
    }
  }
}
.waiting {
  :global {

  }
}

.welcome {
  :global {

  }
}