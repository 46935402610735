@import 'global.scss';

.modal {
  :global {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(31, 31, 31, 0.733);
    display:flex;
    z-index: 999;
    .col {
      align-items: center;
      justify-content: center;
      align-content: center;
      height: 100%;
      display: flex;
      width: 100%;
      flex-direction: column;
      // background: red;
      flex-grow: 1;
      .container {
        min-height: 50vh;
      }
      .row {
        flex: 1;
        &.body {
          border-radius: 10px;
          max-height: 50vh;
          background-color: $blue4;
          width: calc(100% - 30px);
          margin:0;
          padding: 0;
          border: 3px solid $green5;
          position: relative;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .title {
            margin:10px;
            // top: 0;
            // left: 0;
            width: calc(100% - 20px);
            height: 30%;
            // background-color: blue;
            // position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 1.2em;
            color: white;
            span {
              display: flex;
            }
          }
          button {
            @include colored-button($blue3);
          }
        }
      }
    }
  }
}