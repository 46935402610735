// Brother
@font-face {
  font-family: 'Brother-1816-Bold';
  src: local('Brother-1816-Bold'), url('./assets/fonts/Brother-1816-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Brother-1816-Bold-Italic';
  src: local('Brother-1816-Bold-Italic'), url('./assets/fonts/Brother-1816-Bold-Italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Brother-1816-Light';
  src: local('Brother-1816-Light'), url('./assets/fonts/Brother-1816-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// Roboto
@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto-Black'), url('./assets/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-BlackItalic';
  src: local('Roboto-BlackItalic'), url('./assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-BoldItalic';
  src: local('Roboto-BoldItalic'), url('./assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Italic';
  src: local('Roboto-Italic'), url('./assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'), url('./assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-LightItalic';
  src: local('Roboto-LightItalic'), url('./assets/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-MediumItalic';
  src: local('Roboto-MediumItalic'), url('./assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto-Thin'), url('./assets/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-ThinItalic';
  src: local('Roboto-ThinItalic'), url('./assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

.app {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100vw;
  flex-direction: row;
  background-color: #00538C;
  button,
  button:visited,
  button:hover,
  button:active,
  button:focus {
    outline: none;
    box-shadow: none;
  }
}